const companiesTypes = {
  SET_MODAL_CREATE_COMPANY: 'SET_MODAL_CREATE_COMPANY',
  COMPANY_CREATED: 'COMPANY_CREATED',
  COMPANY_UPDATED: 'COMPANY_UPDATED',
  FETCH_ALL_COMPANIES_PAGINATED: 'FETCH_ALL_COMPANIES_PAGINATED',
  FETCH_ONE_COMPANY: 'FETCH_ONE_COMPANY',
  COMPANY_DELETED: 'COMPANY_DELETED',
  MODAL_VIEW_COMPANY: 'MODAL_VIEW_COMPANY',
  MODAL_UPDATE_COMPANY: 'MODAL_UPDATE_COMPANY',
  FETCH_ALL_COMPANIES: 'FETCH_ALL_COMPANIES',
  SET_COMPANY_SELECTED: 'SET_COMPANY_SELECTED',
  REFRESH_NEW_COMPANIES: "REFRESH_NEW_COMPANIES"
};

export default companiesTypes;
