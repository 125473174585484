import usersTypes from '../types/users';

const initialState = {
  allUsersList: [],
  rolesList: [],
  allRoles: [],
  permissionsList: [],
  modalCreateUser: false,
  modalViewUser: false,
  modalUpdateUser: false,
  userSelected: null,
  page: 1,
  totalPages: 1,
  total: 0,
  total_active: 0,
  sectionSelected: 'USERS',
};

const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case usersTypes.SET_USER_SELECTED:
      return { ...state, userSelected: payload.userSelected };
    case usersTypes.SET_ROLES_LIST:
      return { ...state, rolesList: payload.rolesList };
    case usersTypes.SET_ALL_ROLES:
      return { ...state, allRoles: payload };
    case usersTypes.SET_PERMISSIONS_LIST:
      return { ...state, permissionsList: payload.permissionsList };
    case usersTypes.MODAL_CREATE_USER:
      return { ...state, modalCreateUser: payload.modalCreateUser };
    case usersTypes.MODAL_VIEW_USER:
      return { ...state, modalViewUser: payload.modalViewUser };
    case usersTypes.MODAL_UPDATE_USER:
      return { ...state, modalUpdateUser: payload.modalUpdateUser };
    case usersTypes.FETCH_ONE_USER:
      return { ...state, userSelected: payload };
    case usersTypes.FETCH_ALL_USERS:
      return {
        ...state,
        allUsersList: payload.items,
        page: payload.page,
        totalPages: Math.ceil(payload.total / 50),
        total: payload.total,
        total_active: payload.total_active,
      };
    case usersTypes.FETCH_ROLES_PER_TYPE_USER:
      return { ...state, rolesList: payload };
    case usersTypes.FETCH_PERMISSIONS_BY_ROLE:
      return { ...state, permissionsList: payload };
    case usersTypes.SET_USERS_SECTION_SELECTED:
      return { ...state, sectionSelected: payload };

    case usersTypes.RESET_USERS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default usersReducer;
