const authTypes = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  ERROR_LOGIN: 'ERROR_LOGIN',
  GET_AUTHENTICATED: 'GET_AUTHENTICATED',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  SET_RESET_REQUIRED: 'SET_RESET_REQUIRED',
};

export default authTypes;
