import Axios from '../../Axios';
import authTypes from '../types/auth';
import usersTypes from '../types/users';

const loginUser = (credentials) => async (dispatch) => {
  try {
    const response = await Axios.post('/auth/login', credentials);
    const { data } = response;
    console.log(data);
    window.localStorage.setItem('empleado', JSON.stringify(data.user));
    window.localStorage.setItem('token', data.accessToken);



    dispatch({
      type: authTypes.LOGIN_SUCCESS,
      payload: {
        user: data.user,
        authenticated: true,
      },
    });
  } catch (error) {
    console.error(error);
    if (error.response.data.detail) return error.response.data.detail;
  }
};

const refreshToken = () => {
  return async () => {
    try {
      const response = await Axios.post(
        `/auth/changetoken?refreshToken=${window.localStorage.getItem(
          'refreshToken',
        )}`,
      );
      window.localStorage.setItem('token', response.data.token);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

const getLoggedUser = () => {
  const empleado = JSON.parse(localStorage.getItem('empleado'));
  const empresaCliente = localStorage.getItem('empresaCliente')


  return async (dispatch) => {
    try {
      const response = await Axios.post('/auth/me', {
        empresa: empleado?.selected_company,
        empresaCliente: empresaCliente
      });
      const { data } = response;
      console.log(data);
      dispatch({
        type: authTypes.GET_AUTHENTICATED,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

const setPassword = (token, newPassword) => async () => {
  const empresa = await JSON.parse(localStorage.getItem('empresaActual'));
  const response = await Axios.post(
    `/auth/recoverpassword?recover_token=${token}&new_pass=${newPassword}`, {}
    , {
      headers: {
        'Empresa': empresa.rut_empresa
      }
    }
  );
  return response;
};

const forgotPassword = (credentials) => async () => {
  const empresa = await JSON.parse(localStorage.getItem('empresaActual'));
  const response = await Axios.post(`/auth/forgotpassword?ruc=${credentials}`,
    {},
    {
      headers: {
        'Empresa': empresa.rut_empresa
      }
    }
  );
  return response;
};

const checkRecovery = (token) => async () => {
  // const empresa = await JSON.parse(localStorage.getItem('empresaActual'));
  const response = await Axios.post(
    `/auth/check-recovery-token?recover_token=${token}`, {},
    {
      // headers: {
      //   'Empresa': empresa.rut_empresa
      // }
    }
  );
  return response;
};

// const changePasswordFirstLogin = (dataToSubmit) => {
//   return async (dispatch) => {
//     try {
//       const response = await Axios.post(
//         '/auth/respondtoauthchallenge',
//         dataToSubmit,
//       );
//       const { data } = response;
//       window.localStorage.removeItem('session');
//       window.localStorage.removeItem('email');
//       window.localStorage.setItem('token', data.accessToken);
//       window.localStorage.setItem('refreshToken', data.refreshToken);
//       dispatch({ type: authTypes.SET_AUTH_CHALLENGE, payload: false });
//       dispatch({
//         type: authTypes.LOGIN_SUCCESS,
//         payload: {
//           user: data.user,
//           authenticated: true,
//         },
//       });
//     } catch (error) {
//       if (error.response && error.response.data) {
//         if (
//           error.response.data.detail ===
//           'Invalid session for the user, session is expired.'
//         ) {
//           dispatch({
//             type: authTypes.ERROR_LOGIN,
//             payload: {
//               error: true,
//               message: 'Tu sesión expiró. Intenta iniciar nuevmaente sesión.',
//             },
//           });
//         } else if (
//           error.response.data.detail ===
//           'Password does not conform to policy: Password not long enough'
//         ) {
//           dispatch({
//             type: authTypes.ERROR_LOGIN,
//             payload: {
//               error: true,
//               message: 'La contraseña no cumple con las reglas establecidas',
//             },
//           });
//         } else {
//           dispatch({
//             type: authTypes.ERROR_LOGIN,
//             payload: { error: true, message: error.response.data.detail },
//           });
//         }
//       }
//       console.error(error);
//       return error;
//     }
//   };
// };

// const changePasswordProfile = (formData) => {
//   return async (dispatch) => {
//     try {
//       const response = await Axios.post(`/auth/changepassword`, {
//         NewPassword: formData.NewPassword,
//         OldPassword: formData.OldPassword,
//         UserId: formData.UserId,
//       });
//       const { data } = response;
//       dispatch(
//         notificationActions.enqueueNotify(
//           notificationTypes.ACTION_SUCCESS,
//           'Contraseña cambiada exitosamente',
//         ),
//       );
//     } catch (error) {
//       if (error.response.data.detail === 'Incorrect username or password.') {
//         dispatch(
//           notificationActions.enqueueNotify(
//             notificationTypes.ACTION_FAILED,
//             'Contraseña errónea',
//           ),
//         );
//       } else if (
//         error.response.data.detail ===
//         'Attempt limit exceeded, please try after some time.'
//       ) {
//         dispatch(
//           notificationActions.enqueueNotify(
//             notificationTypes.ACTION_FAILED,
//             'Haz alcanzado el límite de intentos, intenta más tarde',
//           ),
//         );
//       } else {
//         dispatch(
//           notificationActions.enqueueNotify(
//             notificationTypes.ACTION_FAILED,
//             'No se pudo cambiar la contraseña',
//           ),
//         );
//       }
//       console.error(error);
//     }
//   };
// };

const logout = () => (dispatch) => {
  try {
    dispatch({ type: authTypes.LOGOUT_SUCCESS });
    dispatch({ type: usersTypes.RESET_USERS_STATE });
    window.localStorage.clear();
  } catch (error) {
    console.error(error);
  }
};

const authActions = {
  loginUser,
  getLoggedUser,
  // refreshToken,
  setPassword,
  forgotPassword,
  checkRecovery,
  // changePasswordFirstLogin,
  // changePasswordProfile,
  logout,
};

export default authActions;
