const config = {
  prod: {
    API_BASE: process.env.REACT_APP_API_URL_PROD
  },
  test: {
    API_BASE: process.env.REACT_APP_API_URL_DEV
  },
  dev: {
    API_BASE: process.env.REACT_APP_API_URL_DEV
  },
  audisoft_api_url: process.env.REACT_APP_CONTABLE_API_URL,
  audisoft_api_key: process.env.REACT_APP_TOKEN_AUDISOFT_API,
  hubspotTest: {
    API_BASE: process.env.REACT_APP_HUBSPOT_ENDPOINT,
  },
  hubspotProd: {
    API_BASE: process.env.REACT_APP_HUBSPOT_ENDPOINT,
  },
};

export default config;