const comunicationTypes = {
  SET_SECTION_SELECTED: 'SET_SECTION_SELECTED',
  SET_CATEGORY_SELECTED: 'SET_CATEGORY_SELECTED',
  SET_COMMUNICATIONS_PAGE: 'SET_COMMUNICATIONS_PAGE',
  SET_COMMUNICATION_SELECTED: 'SET_COMMUNICATION_SELECTED',
  MODAL_CREATE_CATEGORIES: 'MODAL_CREATE_CATEGORIES',
  MODAL_CREATE_SUBCATEGORIES: 'MODAL_CREATE_SUBCATEGORIES',
  MODAL_SELECT_FEATURED: 'MODAL_SELECT_FEATURED',
  FETCH_COMMUNICATIONS: 'FETCH_COMMUNICATIONS',
  FETCH_ONE_COMMUNICATION: 'FETCH_ONE_COMMUNICATION',
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_CATEGORIES_PAGINATED: 'FETCH_CATEGORIES_PAGINATED',
  FETCH_SUBCATEGORIES: 'FETCH_SUBCATEGORIES',
  FETCH_FEATURED: 'FETCH_FEATURED',
  FETCH_LAST_FIVE: 'FETCH_LAST_FIVE',
  FETCH_MOST_VIEWED: 'FETCH_MOST_VIEWED',
  FETCH_RANDOM: 'FETCH_RANDOM',
  SET_FILTER_ACTIVE: 'SET_FILTER_ACTIVE',
  CATEGORY_DELETED: 'CATEGORY_DELETED',
  SUBCATEGORY_DELETED: 'SUBCATEGORY_DELETED',
  COMMUNICATION_DELETED: 'COMMUNICATION_DELETED',
  COMMUNICATION_UPDATED: 'COMMUNICATION_UPDATED',
  UPLOADED_IMAGE: 'UPLOADED_IMAGE',
  UPLOADED_VIDEO: 'UPLOADED_VIDEO',
};

export default comunicationTypes;
