import paymentsTypes from '../types/payments';

const initialState = {
  pendingPayments: [],
  emittedPayments: [],
  paymentsMade: [],
  paymentSelected: null,
  modalViewPayment: false,
  modalSelectTypePayment: false,
  modalTransferForm: false,
  invoicesSelected: [],
  paymentData: null,
  paymentStatusData: null,
  sectionSelected: 'PAYMENTS',
};

const paymentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case paymentsTypes.MODAL_VIEW_PAYMENT:
      return { ...state, modalViewPayment: payload };
    case paymentsTypes.MODAL_VIEW_TYPE_PAYMENT:
      return { ...state, modalSelectTypePayment: payload };
    case paymentsTypes.MODAL_TRANSFER_FORM:
      return { ...state, modalTransferForm: payload };
    case paymentsTypes.SET_INVOICES_SELECTED:
      return { ...state, invoicesSelected: payload };
    case paymentsTypes.SET_PAYMENT_SECTION_SELECTED:
      return { ...state, sectionSelected: payload };
    case paymentsTypes.PAYMENT_STATUS_DATA:
      return { ...state, paymentStatusData: payload };
    case paymentsTypes.SET_PAYMENT_SELECTED:
      return { ...state, paymentSelected: payload };
    case paymentsTypes.FETCH_PENDING_INVOICES:
      return { ...state, pendingPayments: payload };
    case paymentsTypes.FETCH_EMITTED_INVOICES:
      return { ...state, emittedPayments: payload };
    case paymentsTypes.FETCH_PAYMENTS:
      return { ...state, paymentsMade: payload };
    case paymentsTypes.PAYMENT_DATA:
      return { ...state, paymentData: payload };
    default:
      return state;
  }
};

export default paymentsReducer;

