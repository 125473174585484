import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import communications from './comunications';
import companies from './companies';
import support from './support';
import users from './users';
import manuals from './manuals';
import payments from './payments';
import products from './products';
import transfers from './transfers';

const reducers = (history) =>
  combineReducers({
    auth,
    communications,
    companies,
    support,
    users,
    manuals,
    payments,
    products,
    transfers,
    router: connectRouter(history),
  });

export default reducers;

