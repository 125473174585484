import React, { useEffect, useRef, useState } from 'react';
import { GridItem, SimpleGrid, Flex, Input, Button, Text, Box, Image, Skeleton, Grid } from '@chakra-ui/react';
import login_3_bg from '../../assets/images/Login/login_3_bg.jpg'
import ReCAPTCHA from 'react-google-recaptcha';
import Axios from '../../Axios';
import { rutValidator } from '../../validators';
import formatRut from '../../validators/formatRut';
import authActions from '../../state/actions/auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const initState = {
    ruc: '10000000-8',
    password: 'Audisoft123',
    empresa: "",
    empresa_cliente: "",
    tipo: 2,
};


const errorInitialState = {
    state: false,
    type: null,
    text: '',
};

export default function PayWithoutLogin() {
    const recaptcha = useRef()
    const dispatch = useDispatch();
    const history = useHistory();
    const [isInValid, setIsInvalid] = React.useState(true)
    const [formData, setFormData] = useState(initState);
    const [logo, setLogo] = useState(null);
    const [domain, setDomain] = useState(null);
    const [isOnline, setIsOnline] = useState(true);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(errorInitialState);
    const [responseError, setResponseError] = useState(errorInitialState);

    useEffect(() => {

        const fetchGetLogo = async () => {
            let subdomain;
            const hostname = window.location.hostname;
            setLoading(true);
            subdomain = hostname.split('.')[0];
            if (subdomain === 'www') {
                subdomain = hostname.split('.')[1];
            }


            try {
                setDomain(subdomain);

                const response = await Axios.get(`config_empresa/logo?subdomain=${subdomain}`);

                if (response.status === 200) {
                    setIsOnline(true);
                    const { data } = response;
                    // Verificar si data tiene url y rut_empresa
                    if (data && (data.url || data.rut_empresa)) {
                        setLogo(data.url || data); // Establece logo con data.url si está disponible
                        setFormData({ ...formData, empresa: data.rut_empresa, tipo: data.tipo });
                        localStorage.setItem('empresaActual', JSON.stringify(data));
                    } else {
                        setIsOnline(false);
                        console.warn("No logo or empresa found in data");
                        // Puedes establecer valores por defecto o manejar el caso de no haber datos aquí
                        setLogo('ruta/default/logo.png'); // Establece un logo por defecto si no hay logo
                        setFormData({ ...formData, empresa: '', tipo: 2 }); // O un valor por defecto para empresa
                        localStorage.setItem('empresaActual', JSON.stringify(data));
                    }

                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error fetching logo:", error);
            }

        };

        // if (logo == null) {
        //     fetchGetLogo();
        // }
        fetchGetLogo()


    }, []);


    useEffect(() => {
        if (!rutValidator(formData.ruc) || !formData.password) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        console.log(formData.ruc.length);
        console.log(rutValidator(formData.ruc));

        if (formData?.ruc?.length >= 9 && !rutValidator(formData.ruc)) {
            setError({ state: true, type: 'rut', text: 'Rut inválido' });
        } else {
            setError(false);
        }
    }, [formData]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setResponseError(false);
        }, 4000);
        return () => clearInterval(timerId);
    }, [responseError]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'ruc') {
            setFormData({ ...formData, ruc: formatRut(value) });
        } else if (name === 'empresa') {
            setFormData({ ...formData, empresa: formatRut(value) });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleSubmit = async () => {
        setLoading(true);

        try {
            const payload = {
                ...formData,
                ruc: formData.ruc.replaceAll('.', ''),
                empresa: formData.empresa.replaceAll('.', '')
            };

            const response = await dispatch(authActions.loginUser(payload));

            if (response === 'Contraseña incorrecta') {
                setError({
                    state: true,
                    type: 'password',
                    text: response,
                });
            } else if (response === 'Este rut no está registrado') {
                setError({
                    state: true,
                    type: 'rut',
                    text: response,
                });
            } else if (response === 'Este rut empresa no está registrado') {
                setError({
                    state: true,
                    type: 'empresa',
                    text: response,
                });
            } else {
                await new Promise((resolve) => {
                    localStorage.setItem('empleado', JSON.stringify({ empleado: response }));
                    localStorage.setItem('Empresa', formData.empresa.replaceAll('.', ''));
                    localStorage.setItem('empresaCliente', formData.empresa_cliente);
                    resolve(); // This will ensure the redirect only happens after localStorage is set
                });
                history.push('/Pagos');
            }
        } catch (error) {
            console.error("Error during submission: ", error);
        } finally {
            setLoading(false); // Ensure loading is disabled regardless

        }
    };



    async function submitForm(event) {
        event.preventDefault()
        const captchaValue = recaptcha.current.getValue()

        if (!captchaValue) {
            setIsInvalid(true)
        } else {
            setIsInvalid(false)
            handleSubmit()
        }
    }

    return (
        <SimpleGrid columns={1} minHeight="100vh">
            <GridItem colSpan={{ base: 1, lg: 1 }} minHeight="100vh" position="relative">
                {/* Background image */}
                <Image
                    src={login_3_bg}
                    alt="login"
                    boxSize={'full'}
                    objectFit="cover"
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    zIndex={-1}
                    opacity="0.8"
                />

                <Flex direction="column" align="center" justify="center" minHeight="100vh" p={4}>

                    <Box width="350px" boxShadow="lg" borderRadius="md" bg="white" p={6}>
                        <Grid my={3}>

                            {loading && logo == null ? (
                                <Skeleton height={5} width={80} />
                            ) : isOnline ? (
                                <Text color="#A5C918" fontWeight="bold" align="center">
                                    En linea 🟢
                                </Text>

                            ) : (
                                <Text color="#A5C918" fontWeight="bold" align="center">
                                    Sin conexión 🔴
                                </Text>

                            )}


                            {loading && logo == null ? (
                                <Skeleton height={20} width={80} />
                            ) : error.state && error.type === 'empresa' ? (
                                <div>Error al cargar el logo.</div>
                            ) : logo != null ? (
                                <Image src={logo} m="auto" height='65' />
                            ) : null}



                        </Grid>
                        <form onSubmit={submitForm} >

                            {/* <Text fontSize='2xl' mb={4} textAlign='center'></Text> */}
                            <Input placeholder="Ingrese el RUT" width="100%" mb={4}
                                name='empresa_cliente'
                                value={formData.empresa_cliente}
                                onChange={handleChange}
                            />


                            <Box mb={4}>
                                <ReCAPTCHA
                                    ref={recaptcha}
                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                />
                            </Box>
                            <Button colorScheme="blue" width="100%" type="submit"
                                disabled={formData.tipo === 2 ? true : false}
                                isLoading={loading}
                            >
                                Ingresar
                            </Button>

                        </form>

                    </Box>
                </Flex>
            </GridItem>
        </SimpleGrid>
    );
}
