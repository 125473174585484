import manualsTypes from '../types/manuals';

const initialState = {
  allManualsList: [],
  page: 1,
  totalPages: 1,
  manualSelected: null,
  newManual: null,
  setCreate: false,
  setEdit: false,
};

const manualsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case manualsTypes.SET_MANUALS_PAGE:
      return { ...state, page: payload.page };
    case manualsTypes.FETCH_ALL_MANUALS:
      return {
        ...state,
        allManualsList: payload.items,
        page: payload.page,
        totalPages: Math.ceil(payload.total / 50),
      };
    case manualsTypes.FETCH_ONE_MANUAL:
      return { ...state, manualSelected: payload };
    case manualsTypes.NEW_MANUAL:
      return { ...state, newManual: payload };
    case manualsTypes.SET_CREATE_MANUAL:
      return { ...state, allManualsList: [...state.allManualsList, payload] };
    case manualsTypes.SET_EDIT_MANUAL:
      return { ...state, setEdit: payload };
    default:
      return state;
  }
};

export default manualsReducer;
