import companiesTypes from '../types/companies';

const initialState = {
  modalCreateCompany: false,
  companiesList: [],
  companiesListPaginated: [],
  companySelected: null,
  modalViewCompany: false,
  modalUpdateCompany: false,
  page: 1,
  totalPages: 1,
};

const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case companiesTypes.SET_MODAL_CREATE_COMPANY:
      return { ...state, modalCreateCompany: payload.modalCreateCompany };

    case companiesTypes.FETCH_ALL_COMPANIES:
      return { ...state, companiesList: payload };

    case companiesTypes.FETCH_ALL_COMPANIES_PAGINATED:
      return {
        ...state,
        companiesListPaginated: payload.items,
        page: payload.page,
        totalPages: Math.ceil(payload.total / 50),
      };

    case companiesTypes.FETCH_ONE_COMPANY:
      return { ...state, companySelected: payload };

    case companiesTypes.MODAL_VIEW_COMPANY:
      return { ...state, modalViewCompany: payload };

    case companiesTypes.MODAL_UPDATE_COMPANY:
      return { ...state, modalUpdateCompany: payload };

    case companiesTypes.SET_COMPANY_SELECTED:
      return { ...state, companySelected: payload };

    default:
      return state;
  }
};

export default usersReducer;
