import productsTypes from '../types/products';

const initialState = {
  products: [],
  downloadLink: '',
  productSelected: null,
  modalAdd: false,
  sectionSelected: 'USERS',
  modalCreate: false,
  modalUpdate: false,
};

const productsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case productsTypes.MODAL_ADD_PRODUCT:
      return { ...state, modalAdd: payload };
    case productsTypes.MODAL_CREATE_PRODUCT:
      return { ...state, modalCreate: payload };
    case productsTypes.MODAL_UPDATE_PRODUCT:
      return { ...state, modalUpdate: payload };
    case productsTypes.RESET_PRODUCTS:
      return { ...state, products: [] };
    case productsTypes.FETCH_ALL_PRODUCT:
      return { ...state, products: payload };
    case productsTypes.FETCH_CLIENT_PRODUCT:
      return { ...state, products: payload };
    case productsTypes.FETCH_PRODUCT_LINK:
      return { ...state, downloadLink: payload };
    case productsTypes.SET_PRODUCT_SELECTED:
      return { ...state, productSelected: payload };
    case productsTypes.SET_PRODUCT_SECTION_SELECTED:
      return { ...state, sectionSelected: payload };
    default:
      return state;
  }
};

export default productsReducer;

