import authTypes from '../types/auth';

const token = window.localStorage.getItem('token') || null;

const initialState = {
  isAuthenticated: !!token,
  user: null,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case authTypes.LOGOUT_SUCCESS:
      return {
        isAuthenticated: false,
        user: null,
      };
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
        isAuthenticated: true,
      };
    case authTypes.GET_AUTHENTICATED:
      return { ...state, user: payload, isAuthenticated: true };
    default:
      return state;
  }
};

export default authReducer;
