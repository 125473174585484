import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

import reducers from './reducers';

// * -------- Estado inicial de la aplicación
const initialState = {};

// * -------- Middlewares
export const history = createBrowserHistory();
const middlewares = [ReduxThunk, routerMiddleware(history)];

// * -------- Enhancers del store --------
const enhancers = [];

// * -------- Componer Middlewares y Enhancers --------
let composedEnhancers = null;
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  const composeEnhancers = composeWithDevTools({ trace: true });
  composedEnhancers = composeEnhancers(
    applyMiddleware(...middlewares),
    ...enhancers,
  );
} else {
  composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);
}

// * -------- Configuración del Persist --------
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router'],
  whitelist: ['routerLocations', 'auth', 'unsavedVisits'],
};

const persistedReducer = persistReducer(persistConfig, reducers(history));

export default function configureStore() {
  const store = createStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);
  return { store, persistor };
}
