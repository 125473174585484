import { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import authActions from '../state/actions/auth';
import routes from './routes';

function RenderRoutes() {

  const history = useHistory();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [token, setToken] = useState(window.localStorage.getItem('token'));
  const dispatch = useDispatch();

  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
  }, []);

  useEffect(() => {
    const authenticateUser = async () => {
      await dispatch(authActions.getLoggedUser());
    };
    if (token && isAuthenticated) {
      authenticateUser();
      // window.localStorage.clear();
    } else if (token || !isAuthenticated) {
      window.localStorage.clear();
    }
  }, [isAuthenticated, token, history, dispatch]);


  return <Switch>{renderRoutes(routes, { isAuthenticated })}</Switch>;
}

export default RenderRoutes;
