const paymentsTypes = {
  PAYMENT_DATA: 'PAYMENT_DATA',
  PAYMENT_STATUS_DATA: 'PAYMENT_STATUS_DATA',
  MODAL_VIEW_PAYMENT: 'MODAL_VIEW_PAYMENT',
  MODAL_VIEW_TYPE_PAYMENT: 'MODAL_VIEW_TYPE_PAYMENT',
  SET_PAYMENT_SELECTED: 'SET_PAYMENT_SELECTED',
  FETCH_PENDING_INVOICES: 'FETCH_PENDING_INVOICES',
  FETCH_EMITTED_INVOICES: 'FETCH_EMITTED_INVOICES',
  FETCH_PAYMENTS: 'FETCH_PAYMENTS',
  SET_PAYMENT_SECTION_SELECTED: 'SET_PAYMENT_SECTION_SELECTED',
  SET_INVOICES_SELECTED: 'SET_INVOICES_SELECTED',
  MODAL_TRANSFER_FORM: 'MODAL_TRANSFER_FORM',
};

export default paymentsTypes;

