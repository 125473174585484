import transferTypes from '../types/transfers';

const initialState = {
  data: [],
  page: 1,
  totalPages: 1,
  usersTransfers: [],
};

const transferReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case transferTypes.FETCH_ALL:
      return {
        ...state,
        data: payload.items,
        page: payload.page,
        totalPages: Math.ceil(payload.total / 50),
      };
    case transferTypes.FETCH_USER_ALL_TRANSFERS:
      return {
        ...state,
        usersTransfers: payload,
      };
    default:
      return state;
  }
};

export default transferReducer;

