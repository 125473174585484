const productsTypes = {
  FETCH_ALL_PRODUCT: 'FETCH_ALL_PRODUCT',
  FETCH_CLIENT_PRODUCT: 'FETCH_CLIENT_PRODUCT',
  FETCH_PRODUCT_LINK: 'FETCH_PRODUCT_LINK',
  MODAL_ADD_PRODUCT: 'MODAL_ADD_PRODUCT',
  MODAL_CREATE_PRODUCT: 'MODAL_CREATE_PRODUCT',
  SET_PRODUCT_SELECTED: 'SET_PRODUCT_SELECTED',
  SET_PRODUCT_SECTION_SELECTED: 'SET_PRODUCT_SECTION_SELECTED',
  MODAL_UPDATE_PRODUCT: 'MODAL_UPDATE_PRODUCT',
  RESET_PRODUCTS: 'RESET_PRODUCTS',
};

export default productsTypes;

