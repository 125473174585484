import communicationsTypes from '../types/communications';

const initialState = {
  featuredSelected: null,
  categorySelected: null,
  communicationSelected: null,
  modalCreateCategories: false,
  modalCreateSubcategories: false,
  modalSelectFeatured: false,
  filterActive: false,
  sectionSelected: 'Noticias',
  categoriesList: [],
  categoriesListPaginated: [],
  subcategoriesList: [],
  communicationsList: [],
  mostViewedList: [],
  randomList: [],
  lastFive: [],
  page: 1,
  pageCategories: 1,
  totalPages: 1,
  totalPagesCategories: 1,
};

const communicationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case communicationsTypes.MODAL_CREATE_CATEGORIES:
      return { ...state, modalCreateCategories: payload.modalCategories };
    case communicationsTypes.MODAL_CREATE_SUBCATEGORIES:
      return { ...state, modalCreateSubcategories: payload.modalSubcategories };
    case communicationsTypes.MODAL_SELECT_FEATURED:
      return { ...state, modalSelectFeatured: payload.modalSelectFeatured };
    case communicationsTypes.SET_SECTION_SELECTED:
      return { ...state, sectionSelected: payload.sectionSelected };
    case communicationsTypes.SET_CATEGORY_SELECTED:
      return { ...state, categorySelected: payload.categorySelected };
    case communicationsTypes.SET_COMMUNICATIONS_PAGE:
      return { ...state, page: payload.page };
    case communicationsTypes.SET_COMMUNICATION_SELECTED:
      return { ...state, communicationSelected: payload.communicationSelected };
    case communicationsTypes.FETCH_COMMUNICATIONS:
      return {
        ...state,
        communicationsList: payload.items,
        page: payload.page,
        totalPages: Math.ceil(payload.total / 50),
      };
    case communicationsTypes.FETCH_ONE_COMMUNICATION:
      return { ...state, communicationSelected: payload };
    case communicationsTypes.FETCH_CATEGORIES:
      return { ...state, categoriesList: payload };
    case communicationsTypes.FETCH_CATEGORIES_PAGINATED:
      return {
        ...state,
        categoriesListPaginated: payload.items,
        pageCategories: payload.page,
        totalPagesCategories: Math.ceil(payload.total / 10),
      };
    case communicationsTypes.FETCH_SUBCATEGORIES:
      return { ...state, subcategoriesList: payload };
    case communicationsTypes.FETCH_FEATURED:
      return { ...state, featuredSelected: payload };
    case communicationsTypes.FETCH_LAST_FIVE:
      return { ...state, lastFive: payload };
    case communicationsTypes.FETCH_MOST_VIEWED:
      return { ...state, mostViewedList: payload };
    case communicationsTypes.FETCH_RANDOM:
      return { ...state, randomList: payload };
    case communicationsTypes.SET_FILTER_ACTIVE:
      return { ...state, filterActive: payload.filterActive };

    default:
      return state;
  }
};

export default communicationsReducer;
