import supportTypes from '../types/support';

const initialState = {
  modalCreateSupport: false,
  ticketSelected: null,
  allTicketsList: [],
  customerTicketsList: [],
  hubspotStages: [],
  hubspotOwners: [],
  hubspotProducts: [],
  hubspotTicketsPriority: [],
};

const supportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case supportTypes.MODAL_CREATE_SUPPORT:
      return { ...state, modalCreateSupport: payload.modalCreateSupport };
    case supportTypes.SET_TICKET_SELECTED:
      return { ...state, ticketSelected: payload.ticketSelected };
    case supportTypes.FETCH_ALL_TICKETS:
      return { ...state, allTicketsList: payload };
    case supportTypes.FETCH_CUSTOMER_TICKETS:
      return { ...state, customerTicketsList: payload };
    case supportTypes.FETCH_SINGLE_TICKET_DETAIL:
      return { ...state, ticketSelected: payload };
    case supportTypes.GET_STAGES_PIPELINES:
      return { ...state, hubspotStages: payload };
    case supportTypes.GET_HUBSPOT_OWNERS:
      return { ...state, hubspotOwners: payload };
    case supportTypes.GET_HUBSPOT_TICKETS_PRIORITY:
      return { ...state, hubspotTicketsPriority: payload };
    case supportTypes.GET_HUBSPOT_PRODUCTS:
      return { ...state, hubspotProducts: payload };
    default:
      return state;
  }
};

export default supportReducer;

