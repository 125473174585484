import { Grid, CircularProgress } from '@chakra-ui/react';

import logo from '../../assets/images/logo.png';

const Progress = ({
  loading,
  size,
  thickness,
  showLogo,
  center,
  centerVertically,
  height,
  width,
}) => {
  return loading ? (
    <Grid
      zIndex={1050}
      display="flex"
      flexDirection="column"
      alignItems={centerVertically ? 'center' : 'flex-start'}
      justifyContent={center ? 'center' : 'flex-start'}
      height={height ? `${height}px` : '100%'}
      width={width ? `${width}px` : '100%'}
    >
      <CircularProgress
        size={size || 40}
        thickness={thickness || 5}
        color={'#A5C918'}
      />
      {showLogo && (
        <img
          src={logo}
          alt="Audisoft"
        />
      )}
    </Grid>
  ) : null;
};


export default Progress;
