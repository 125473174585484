const supportTypes = {
  MODAL_CREATE_SUPPORT: 'MODAL_CREATE_SUPPORT',
  SET_TICKET_SELECTED: 'SET_TICKET_SELECTED',
  FETCH_ALL_TICKETS: 'FETCH_ALL_TICKETS',
  FETCH_CUSTOMER_TICKETS: 'FETCH_CUSTOMER_TICKETS',
  FETCH_SINGLE_TICKET_DETAIL: 'FETCH_SINGLE_TICKET_DETAIL',
  TICKET_CREATED: 'TICKET_CREATED',
  GET_HUBSPOT_OWNERS: 'GET_HUBSPOT_OWNERS',
  GET_HUBSPOT_TICKETS_PRIORITY: 'GET_HUBSPOT_TICKETS_PRIORITY',
  GET_HUBSPOT_PRODUCTS: 'GET_HUBSPOT_PRODUCTS',
  GET_STAGES_PIPELINES: 'GET_STAGES_PIPELINES',
};

export default supportTypes;

