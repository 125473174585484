const manualsTypes = {
  SET_MANUALS_PAGE: 'SET_MANUALS_PAGE',
  FETCH_ALL_MANUALS: 'FETCH_ALL_MANUALS',
  FETCH_ONE_MANUAL: 'FETCH_ONE_MANUAL',
  NEW_MANUAL: 'NEW_MANUAL',
  MANUAL_UPDATED: 'MANUAL_UPDATED',
  SET_CREATE_MANUAL: 'SET_CREATE_MANUAL',
  SET_EDIT_MANUAL: 'SET_EDIT_MANUAL',
};

export default manualsTypes;
